@use 'styles/variables' as v;
@use 'styles/mixins' as m;

#trade-currency {
    margin-bottom: v.$bigPad;

    @media (width >= v.$phone) {
        margin-bottom: v.$largePad;
    }

    @media (width >= v.$desktop) {
        margin-bottom: v.$hugePad;
    }

    .content-body-small {
        &.skeleton-loading {
            max-height: 60px;
        }
    }

    .container {
        padding-inline: v.$regularPad;
        @include m.container-fluid();

        @media (width >= v.$extrawidescreen) {
            padding-inline: 0;
            max-width: 1560px;
        }
    }

    section {
        margin-inline: v.$regularPad;
        margin-block: v.$bigPad;

        @media (width >= v.$widescreen) {
            margin-block: v.$hugePad v.$bigPad;
        }

        @media (width >= v.$extrawidescreen) {
            padding-inline: 0 !important;
            margin-inline: 0;
        }
    }

    .section-dropdown-content {
        margin-block: v.$regularPad;
    }

    .content-title {
        p, h2, h1 {
            @include m.font(v.$extralarge, 500, v.$huge, 0.01em, v.$color-grey-800);
            margin-bottom: 8px;
        }

        &:not(.content-title.dropdown-content) {
            p, h2, h1 {
                margin-bottom: v.$regularPad;
            }
        }

        &.dropdown-content {
            p, h2, h1 {
                margin-bottom: 8px !important;
            }
        }

        &.skeleton-loading {
            height: 28px;
            width: 500px;

            &.list-content-title {
                @media (width >= v.$desktop) {
                    width: 34vw;
                    max-width: 650px;
                }
            }
        }
    }

    .paragraph-text .content-title:not(.content-title-plus) p {
        width: 80%;

        @media (width >= v.$widescreen) {
            width: 669px;
        }
    }

    .content-title-cards {
        p, h2, h1 {
            margin-bottom: v.$tiny;
        }
    }

    .content-title-plus {
        p, h2, h1 {
            margin-bottom: v.$largeplus;
        }
    }

    .content-title-card {
        p, h2, h1 {
           @include m.font(v.$large, null, v.$medium, 0.45px);
           margin-bottom: 4px;
       }
    }

    .content-body {
        p, ul, ol {
            @include m.font(v.$medium, 400, v.$moreplushuge, 0.015em, v.$color-grey-600);
            margin-bottom: v.$regularPad;

            &:last-child {
                margin-bottom: 0;
            }

            @include m.default-anchor();
        }

        &.dropdown-content {
            @include m.font(v.$medium, 400, v.$moreplushuge, 0.015em, v.$color-grey-600);
            
            & p:last-child {
                margin-bottom: v.$bigPad;
            }
        }

        &.skeleton-loading {
            height: 180px;
        }
    }

    .content-body-last p:last-child {
        margin-bottom: 0 !important;
    }

    .content-body-small {
        p, ul, ol {
            margin-bottom: 0;
        }
    }

    .content-body-plus {
        p, ul, ol {
            margin-bottom: 0;

            &:last-child {
                margin-bottom: v.$hugePad;
            }
        }
    }

    .content-body-card {
        p, ul, ol {
            font-size: v.$medium;
            line-height: v.$huge;
            margin-bottom: 0;

            &:last-child {
                margin-bottom: 0;
            }
        }
    }

    .content-body-banner {
        p, ul, ol {
            margin-bottom: 0;

            &:last-child {
                margin-bottom: 0;
            }
        }
    }

    .banner {
        #page-content-image {
            max-height: fit-content;
            display: block;
        }

        img {
            max-height: 244px;
            min-height: 244px;
            border-radius: 4px;
            transform: none;
            @include m.relativeOffset(0, true, false);

            @media (width <= v.$tablet) {
                min-height: 214px;
            }

            @media (width <= v.$phone) {
                min-height: 164px;
            }
        }
    }

    .paragraph-img {
        #page-content-image {
            @include m.container-min-height-fluid(450px);
            display: block;

            @media (width <= v.$tablet) {
                @include m.container-min-height-fluid(400px);

                img {
                    @include m.container-min-height-fluid(400px);
                }
            }

            @media (width <= v.$phone) {
                @include m.container-min-height-fluid(335px);

                img {
                    @include m.container-min-height-fluid(335px);
                }
            }
        }

        img {
            border-radius: 4px;
            transform: none;
            @include m.relativeOffset(0, true, false);
            @include m.container-min-height-fluid(450px);
        }
    }

    .qc-img {
        #page-content-image {
            max-height: fit-content;
            min-height: 70px;
            @include m.square(70px);
            display: block;
            margin: v.$bigPad 74px;

            @media (width <= v.$tablet) {
                min-height: 70px;
                margin-block: v.$bigPad v.$regularPad;
                @include m.square(70px);
            }

            @media (width <= v.$phone) {
                min-height: v.$hugePad;
                margin-block: v.$bigPad v.$regularPad;
                @include m.square(v.$hugePad);
            }
        }

        img {
            border-radius: 4px;
            transform: none;
            @include m.relativeOffset(0, true, false);
        }
    }

    .first-paragraph {
        margin: 0 0 v.$bigPad;
        @include m.flex(initial, initial, column);

        .content-body {
            p {
                @include m.font(v.$medium, 400, 32px, 0.24px, v.$color-grey-600);
                margin-bottom: v.$regularPad;
            }
        }
    }

    .section-paragraph-text-img {
        @include m.flex(center, space-between);
        gap: 100px;

        .paragraph-text {
            width: 996px;
            margin-block: auto;
        }

        .content-body {
            p, ul, ol {
                margin-bottom: 0;
            }
        }

        @media (width <= v.$widescreen) {
            .paragraph-text {
                width: 50%;
                max-width: 618px;
            }
        }

        @media (width <= v.$tablet) {
            gap: 0;

            &.img-left {
                flex-direction: column;
            }

            &.img-right {
                flex-direction: column-reverse;
            }

            .paragraph-text {
                @include m.container-fluid();
                margin-top: v.$bigPad;
            }
        }
    }

    .section-paragraph-banner {
        .banner img {
            margin-bottom: v.$hugePad;
        }

        @media (width <= v.$tablet) {
            margin-bottom: v.$bigPad;
        }
    }

    .section-paragraph-quad-cards {
        margin-top: v.$hugePad;
        margin-bottom: 120px;
        padding: 0;
        border-bottom: none;

        .content {
            @include m.flex(center, space-between);
            gap: 107px;
        }

        .cards {
            display: grid;
            gap: v.$medium;
            grid-template-columns: repeat(2, 1fr);
            grid-template-rows: repeat(2, 1fr);
            place-items: center;
            width: 452px
        }

        .card {
            background-color: v.$white;
            border-radius: 4px;
            border: none;
            height: 250px;
            width: 218px;
            text-align: center;

            .card-content {
                margin: 0 v.$regularplus auto;

            }
        }

        .paragraph-text {
            width: auto;
        }

        .banner img {
            margin-top: v.$hugePad;
        }

        @media (width <= v.$widescreen) {
            .content {
                @include m.flex(center, space-between, column);
                gap: 0;
            }

            .paragraph-text {
                margin-top: v.$hugePad;
            }

        }

        @media (width <= v.$tablet) {
            margin-block: v.$bigPad v.$largePad;

            .content {
                flex-direction: column-reverse;
            }

            .cards {
                gap: v.$medium 21.6px;
                width: 100%;
            }

            .card {
                height: 280px;
                width: revert;

                .card-content {
                    margin: 0 v.$bigPad auto;
                }
            }

            .paragraph-text {
                margin-block: 0 v.$bigPad;
            }

            .banner img {
                margin-top: v.$bigPad;
            }
        }

        @media (width <= v.$phone) {
            margin-bottom: v.$bigPad;

            .cards {
                gap: v.$regularplus;
                grid-template-columns: repeat(1, 1fr);
                grid-template-rows: repeat(4, 1fr);
            }

            .card {
                max-width: 334px;
                width: 100%;
            }
        }

    }

    .crypto-price-cards-container {
        filter: drop-shadow(0 1px 40px rgba(0, 0, 0, 0.06));
        margin-bottom: v.$bigPad;
        overflow: auto hidden;

        &::-webkit-scrollbar {
            display: none;
        }

        #cx-crypto-price-card {
            .head-container-card {
                padding: 24px 20px 0 28px;
            }

            .currency-name {
                @include m.font(v.$medium, 400, v.$moreplushuge, 0.015em, v.$color-accent-500);
                margin-top: 8px;
            }

            .price-container {
                @include m.font(v.$largeplus, 500, v.$extralarge, 0.0025em, v.$color-grey-900);
                margin-top: 0;

                @media (width >= v.$desktop) {
                    margin-top: 8px;
                }
            }

            .number-flip:not(.currency-price) {
                @include m.font(v.$medium, 400, v.$moreplushuge, 0.015em);
            }

            .margin-left-row-numbers {
                padding-inline: 23px;
                margin-inline: 0;

                &.skeleton-loading {
                    margin-inline: 28px;
                }

                @media (width >= v.$desktop) {
                    padding-inline: 30px v.$bigPad;
                    margin-bottom: 8px;
                }
            }

            .material-icons {
                margin-left: -3px;
            }

            .upward-trend .material-icons {
                margin-top: 3px;
            }

            .downward-trend .material-icons {
                margin-top: 4px;
            }

            .container-graph {
                margin: 0;
                height: 50px;

                @media (width >= v.$phone) {
                    height: 46px;
                }

                @media (width >= v.$desktop) {
                    height: 71px;
                }
            }

            .footer-container-card {
                padding-top: 0;
                
                @media (width >= v.$desktop) {
                    padding-top: 12px;
                }
            }
        }

        .extra-crypto-price-card,
        #cx-crypto-price-card {
            width: 164px;
            height: 242px;

            @media (width >= v.$phone) {
                width: 200px;                
            }

            @media (width >= v.$desktop) {
                width: 256px;
                height: 298px;
            }
        }

        > div {
            gap: v.$regularPad;

            @media (width >= v.$desktop) {
                gap: v.$largePad;                
            }
        }

        @media (width <= v.$phone) {
            padding-inline: 0px;
        }
    }

    .extra-crypto-price-card {
        width: 100%;
        height: 297px;
        background-color: v.$white;
        border: 1px solid v.$cx-divider;
        border-radius: v.$small;
        padding: v.$huge;
        display: flex;
        flex-direction: column;
        justify-content: space-between;

        .extra-card-text {            
            margin-top: 8px;
            @include m.font(v.$largeplus, 500, v.$extralarge, 0.015em, v.$color-accent-500);
        }

        @media (width <= v.$widescreen) {
            padding: v.$largeplus v.$regularplus v.$largeplus v.$largeplusplus;
        }

        @media (width <= v.$desktop) {
            height: 242px;
            padding: v.$largeplus v.$largeplus v.$largeplus v.$largeplusplus;
        }

        @media (width <= v.$tablet) {
            width: 199px;
            padding: v.$largeplus v.$regularplus v.$largeplus v.$largeplus;
        }

        @media (width <= v.$phone) {
            padding: v.$regularPad;
            width: 165px;

            .extra-card-text {
                font-size: v.$medium;
            }
        }

        &:hover, &:active {
            border: 1px solid v.$purplehover;
            cursor: pointer;
        }

        &:active {
            border: 2px solid v.$purplehover;
        }
    }

    .extra-crypto-price-card-space {
        display: none;

        @media (width <= v.$desktop) {
            content: "";
            display: inline-block;
            width: v.$largeplus;
        }
    }

    .learn-more-link,
    .learn-more-link-extra-card {
        span {
            max-width: max-content;
            white-space: nowrap;
            @include m.font(v.$medium, 500, v.$largeplus, 0.0025em, v.$purple-text);

            &.material-icons {
                font-size: v.$extralarge;
            }
        }

        &.skeleton {
            width: 111px;
            height: 21px;
        }

        &:hover span:first-child {
            text-decoration: underline;
        }
    }

    .learn-more-cards-container {
        margin-bottom: v.$regularPad;
        @include m.grid-gap(v.$regularPad, true, false, 1);
            
        @media (width >= v.$widescreen) {
            @include m.grid-gap(v.$regularPad, false, true, 4);
        }

        #cx-learn-more-card {
            width: 100%;
            padding: v.$bigPad v.$regularPad;
            border-radius: v.$thinborder;
            background-color: var(--cg-grey-200);

            @media (width >= v.$phone) {
                height: 232px;
            }
            
            @media (width >= v.$widescreen) {
                height: 324px;
            }

            @media (width >= v.$extrawidescreen) {
                padding: v.$bigPad;                
            }

            .lines-overflow {
                p {
                    @include m.font(v.$medium, 400, v.$moreplushuge, 0.015em, v.$color-grey-900);
                }
            }

            .learn-more-title {
                height: unset;
                -webkit-line-clamp: none;

                p, h2, h3 {
                    @include m.font(v.$medium, 600, v.$extralarge, 0.05em, v.$color-grey-900);
                    margin: 0;

                    @media (width >= v.$phone) {
                        @include m.font(v.$extralarge, 700, v.$huge, 0.05em, v.$color-grey-900);
                    }
                    
                    @media (width >= v.$desktop) {
                        letter-spacing: 0.015em;
                    }
                }
            }

            .learn-more-button {
                --cds-button-width: 248px;
            }
        }
    }

    #cx-seo-dropdown {
        margin-inline: v.$regularPad;

        .seo-dropdown-content {
            margin-top: v.$regularPad;

            h2:first-of-type {
                padding-top: v.$regularPad;
            }
        }

        @media (width >= v.$extrawidescreen) {
            margin-inline: 0;
        }
    }

    @media (width <= v.$tablet) {
        .only-destop {
            display: none;
        }

        .content-title-cards {
            p, h2, h1 {
                margin-bottom: v.$largeplus;
            }
        }

        .content-title-plus {
            p, h2, h1 {
                line-height: v.$largeplus;
                margin-bottom: v.$largeplus;
            }
        }

        .content-title-line {
            margin-bottom: v.$hugeplus;
        }

        .content-body-plus {
            p, ul, ol {
                margin-bottom: 0;

                &:last-child {
                    margin-bottom: v.$bigPad;
                }
            }
        }

        .content-title-card {
            p, h2, h1 {
                font-size: v.$medium;
            }
        }

        .content-body-card {
            p, ul, ol {
                font-size: v.$regular;
                line-height: v.$huge;
            }
        }
    }

    @media (width <= v.$phone) {
        .content-title {
            p, h2, h1 {
                text-wrap: wrap;
                overflow: overlay;
                text-overflow: ellipsis;
            }

            &.skeleton-loading {
                width: 300px;
            }
        }

        .content-title-cards,
        .content-title-plus {
            p, h2, h1 {
                margin-bottom: v.$extrahugetitle;
            }
        }

        .content-title-line {
            margin-bottom: v.$extraextrahuge;
        }
    }

    section.trade-steps {
        @include m.flex(initial, space-between, row);
        max-width: v.$grid-width-desktop;
        margin-top: 0;
        margin-bottom: v.$giantsmall;

        @media (width < v.$desktop) {
            @include m.flex-gap(40px, initial, space-between, column);
            margin: 0;
            margin-bottom: v.$bigPad;
        }

        .trade-step {
            @include m.flex-gap(28px, start, initial, row);
            max-width: 466px;

            @media (width < v.$desktop) {
                max-width: unset;
            }

            @media (width <= v.$phone) {
                @include m.flex-gap(28px, center, center, column);
                text-align: center;
            }
            
            .lc-icon {
                --cds-icon-color: var(--cx-secondary-700);
                cds-icon {
                    --md-icon-size: 24px;
                }

                background-color: v.$color-grey-250;
                @include m.flex(center, center);
                @include m.square(v.$largePad);
                min-width: v.$largePad;
                border-radius: 100%;
                margin-inline: 0;
            }

            .content-title {
                p {
                    @include m.font(v.$largeplus, 500, 24px, null, v.$color-grey-800);
                    margin-bottom: 4px;

                    @media (width < v.$desktop) {
                        margin-bottom: 0;
                    }
                }
            }

            .content-body {
                p {
                    @include m.font(v.$medium, 400, 32px, 0.24px, v.$color-grey-800, 0.75);
                    max-width: 378px;

                    @media (width < v.$desktop) {
                        max-width: unset;
                    }
                }
            }
        }
    }
}
