@import 'styles/variables';

#trade {
    margin-top: 65px;
    background-color: $color-grey-100;
    
    @media (width <= $tablet) {
        margin-top: 60px;
    }

    @media (width <= $phone) {
        background-color: $color-grey-100;
    }

    .banner-item {
        background-color: transparent !important;
    }

    .get-started-container {
        z-index: 1;

        > .get-started {
            width: 100%
        }
    }

    .trade-content {
        margin: auto;
        margin-bottom: 100px;
        padding: 0 20px;

        @media (max-width: $desktop) {
            grid-template-columns: repeat(2, 50%);
            padding: 0;
            margin: 79px 0 60px 0;
        }
    
        @media (max-width: $phone) {
            grid-template-columns: repeat(1, 100%);
            margin-top: 40px;
        }

        .container {
            width: 100vw;
            max-width: 1364px;
    
            @media (max-width: $extrascreen) {
                max-width: calc(100% - 40px);
            }
    
            @media (max-width: $phone) {
                max-width: 100%;
            }
        }
    }

    .content-title p {
        color: $black;
        font-size: 36px;
        font-weight: 500;
        line-height: 60px;
        letter-spacing: 0.448px;
    }

    .content-body p {
        width: 100%;
        color: $black;
        font-size: 16px;
        line-height: 32px;
        letter-spacing: 0.512px;
        margin-bottom: 40px;
    }

    .trade-container {
        padding-bottom: 40px;

        @media (width >= $tablet) {
            padding-bottom: 40px;            
        }

        @media (width >= $desktop) {
            padding-bottom: 80px;
        }
    }
}
